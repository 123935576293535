
import { Component, Vue, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { ArticleService } from "src/services";
import tabbar from "src/components/tabbar";
import { tools } from "src/utils";
import { Payment_Type } from "src/enums";
import router from "src/router";

@Component({
    components:
    {
        tabbar
    }
})
export default class HealthProductArticle extends Vue
{

    /**
     * 文章id
     * @private
     * @returns any
     */
    private articleInfo: any =
    {
        author: "",
        content:"",
        cover:"",
        id: null,
        source:"",
        status: 1,
        title: ""
    }

    /**
     * 文章id
     * @private
     * @returns string
     */
    private get id(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 获取文章列表
     * @private
     * @returns void
     */
     private async getHealthArticleDetail(): Promise<void>
    {
        try
        {
            let { content: result } = await ArticleService.instance.getHealthArticleDetail(this.id);
            if(result.data)
            {
                this.articleInfo = result.data || [];
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
     private created(): void
    {
        this.getHealthArticleDetail();
    }

    
}
